import { FC, useEffect, useRef } from 'react'
import clsx from 'clsx'

import useAlert from 'store/common/useAlert'

import { IAlertMessage } from 'constants/alert'

import commonStyles from '../alerts.module.scss'
import styles from './alert-ai-bot.module.scss'

interface IAlertBase {
  message?: IAlertMessage
  isError?: boolean
}

const AlertAiBot: FC<IAlertBase> = ({ message, isError }: IAlertBase) => {
  const timer = useRef<any>(null)
  const { deleteSingleAlert } = useAlert()

  useEffect(() => {
    timer.current = setTimeout(() => {
      message && deleteSingleAlert(message)
    }, 5000)

    return () => clearTimeout(timer.current)
  }, [])

  return (
    <div
      className={clsx(commonStyles.popupWrapper, styles.popupWrapper, {
        [styles.alertError]: isError,
      })}
    >
      <div className={clsx(styles.notificationBG, { [styles.bgError]: isError })}>
        {!isError && (
          <div className={styles.successSign}>
            <div className={styles.successLine} />
            <div className={styles.successDot} />
          </div>
        )}
        {isError && (
          <div className={styles.warningSign}>
            <div className={styles.warningLine} />
            <div className={styles.warningDot} />
          </div>
        )}
        <div className={styles.textWrap}>
          <div className={styles.title}>{message?.title}</div>

          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: message?.text || 'Изменения успешно сохранены' }}
          />
        </div>
      </div>
    </div>
  )
}

export default AlertAiBot
