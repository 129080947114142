import { FC, useEffect } from 'react'

import StickyBannerWrapper from 'containers/sticky-banner-wrapper/sticky-banner-wrapper'
import useBannerTelegramBottom from 'store/popups/useBannerTelegramBottom'
import useGetTgLinkWithId from 'hooks/useGetTgLinkWithId'

import A from 'utils/metrics/analytic'
import { PROD_TG_BOT, STATIC_URL } from 'constants/data'
import { A_TG_BANNER_CLICK, A_TG_BANNER_SHOWN } from 'constants/analytic-data'

import styles from './banner-telegram-bottom.module.scss'

const imgPath = {
  plane: `${STATIC_URL}/banners/telegram-plane-big.png`,
  btnPlane: `${STATIC_URL}/banners/icon_sticky_tg.png`,
}

const planeImgData = {
  src: imgPath.plane,
  height: 130,
  width: 144,
  alt: 'самолет',
}
const btnImgData = {
  src: imgPath.btnPlane,
  height: 23,
  width: 23,
  alt: 'самолет',
  unoptimized: true,
}

const BannerTelegramBottom: FC = () => {
  const { closeBannerTelegramBottom } = useBannerTelegramBottom()
  const telegramLink = useGetTgLinkWithId(PROD_TG_BOT)

  const buttonClickHandler = () => {
    window.open(telegramLink, '_blank')
    closeBannerTelegramBottom()
    A.setEvent(A_TG_BANNER_CLICK)
  }

  const buttonData = {
    buttonText: 'Подписаться на Telegram',
    buttonClickHandler: buttonClickHandler,
    icon: btnImgData,
    iconMod: styles.modIcon,
  }

  useEffect(() => {
    A.setEvent(A_TG_BANNER_SHOWN)
  }, [])

  return (
    <StickyBannerWrapper
      title="Получите уведомление за час до начала мероприятия через Telegram"
      buttonData={buttonData}
      bannerImageData={planeImgData}
      closeBanner={closeBannerTelegramBottom}
    />
  )
}

export default BannerTelegramBottom
