import { useAuth } from 'store/auth/useAuth'

const useGetTgLinkWithId = (tgLink: string): string => {
  const { userProfile } = useAuth()

  const resultTgLink = userProfile.id ? `${tgLink}/?start=${userProfile.id}` : tgLink

  return resultTgLink
}

export default useGetTgLinkWithId
